import { RouteRecordRaw } from 'vue-router';

import { sendRequest } from '@/hooks/useApi';

// [-] Pinia
import { storeToRefs } from 'pinia';
import piniaStore from '@/store';

const routes: RouteRecordRaw[] = [
    {
        name: 'Login',
        path: '/login',
        component: () => import('@/pages/Login.vue'),
        meta: { title: '登入', icon: 'login' },
    },
    {
        name: 'Forget',
        path: '/forget',
        component: () => import('@/pages/Forget.vue'),
        meta: { title: '忘記密碼', icon: 'address-book' },
    },
    {
        name: 'PatientList',
        path: '/patient-list',
        component: () => import('@/pages/PatientList.vue'),
        meta: { requireAuth: true, title : '患者列表', icon: 'ballot' },
        redirect: '/patient-list/1',
        children: [
            {
                name: 'patient-list-p',
                path: ':page',
                component: () => import('@/components/organism/orga-patientTbody.vue'),
                meta: { requireAuth: true, title: '患者列表', icon: 'ballot' },
                // props: () => ({ patientList: [] })
            },
        ],
        beforeEnter: async (to) => {
            const patientStore = piniaStore.usePatientStore;
            patientStore.resetPatient();
        }
    },
    {
        name: 'Patient',
        path: '/patient/:patient_id(\\d+)', // 只能是數字
        component: () => import('@/pages/Patient.vue'),
        meta: { requireAuth: true, title: '患者資料', icon: 'ballot' },
        children: [
            {
                name: 'TubeInfo',
                path: 'tube-info',
                component: () => import('@/pages/patient/TubeInfo.vue'),
                meta: { requireAuth: true, title: '廔管資料', icon: 'image' },
            },
            {
                name: 'SurgeryRecords',
                path: 'surgery-records',
                component: () => import('@/pages/patient/SurgeryRecords.vue'),
                meta: { requireAuth: true, title: '手術紀錄', icon: 'ballot' },
            },
            {
                name: 'OutpatientRecords',
                path: 'outpatient-records',
                component: () => import('@/pages/patient/OutpatientRecords.vue'),
                meta: { requireAuth: true, title: '回診紀錄', icon: 'book_2' },
                redirect: 'outpatient-records/1',
                children: [
                    {
                        name: 'OutpatientRecordsList',
                        path: ':page(\\d+)',
                        component: () => import('@/components/organism/orga-outpatientTbody.vue'),
                        meta: { requireAuth: true, title: '回診紀錄', icon: 'book_2' },
                        // props: () => ({ patientList: [] })
                    },
                ],
            },
            {
                name: 'MessageBoard',
                path: 'message-board',
                component: () => import('@/pages/patient/MessageBoard.vue'),
                meta: { requireAuth: true, title: '留言板', icon: 'forum' },
            },
            {
                name: 'DataAnalysis',
                path: 'data-analysis',
                component: () => import('@/pages/patient/DataAnalysis.vue'),
                meta: { requireAuth: true, title: '數據分析', icon: 'dns' },
            },
        ],
        beforeEnter: async (to) => {
            const elStatusStore = piniaStore.useElStatusStore;

            const userStore = piniaStore.useUserStore;
            const { userState } = storeToRefs(userStore);
            const userPermissions = userState.value.data.role.permissions;

            // 一定會有 patient_id 沒有的話踢去病患列表
            const patientId = Number(to.params?.patient_id) || 0;


            // [-]判斷權限
            if (to.path !== `/patient/${patientId}` &&
                !userPermissions.some((item) => {
                    return (
                        to.path === `/patient/${patientId}/${item.href}` ||
                        to.path === `/patient/${patientId}/${item.href}/${to.params?.page}`
                    );
                })
            ) { // 沒權限就登出
                console.log('權限消失');
                userStore.signOut();
                elStatusStore.endLoading(); // 關閉遮罩
                return {name: 'Login'};
            }

            // [-]如果是剛進病患頁面 導向到廔管資料
            if (to.path === `/patient/${patientId}`) {
                const params = { patient_id: patientId };

                elStatusStore.endLoading(); // 關閉遮罩
                return {name: 'TubeInfo', params};
            }

            if(!patientId){ // 沒選定患者就去導向患者列表
                elStatusStore.endLoading(); // 關閉遮罩
                return {name: 'PatientList', params: {page: 1}};
            } else {
                const patientStore = piniaStore.usePatientStore;
                const { patientState } = storeToRefs(patientStore);

                // 如果沒有病患資料的時候 || 當前病患資料不是這個病患的時候
                if(patientState.value.info.id === 0 || patientState.value.info.id != patientId){

                    // 重新取得病患資料
                    const url = (__DOCKING__)?
                        `/api/present_pat/simpledata/${patientId}` :
                        `/mapi/patient/info?patient_id=${patientId}`;

                    await sendRequest(url).then((res) => {
                        if (res?.status) {
                            if (__DOCKING__) {
                                const patientInfo = res.data;

                                patientState.value.info = {
                                    id: patientInfo.patient_id,
                                    name: patientInfo.name,
                                    uid: patientInfo.identification,
                                    age: patientInfo.age,
                                    place: patientInfo.section_note,
                                    next: patientInfo.next_appointment,
                                    doctor: patientInfo.doctor,
                                };
                                patientState.value.text_record = patientInfo.text_record;
                                patientState.value.survey = patientInfo.survey;

                            } else {
                                patientState.value.info = res.data.info;
                                patientState.value.text_record = res.data.tube;
                                patientState.value.survey = res.data.questionnaire;
                            }

                            return true;
                        }
                    });
                }
            }
        }
    },
    {
        name: 'Develop',
        path: '/develop',
        component: () => import('@/pages/develop/index.vue'),
        redirect: '/develop/ui-ux-set',
        meta: { title: '開發用頁面', icon: 'address-book' },
        children: [
            {
                name: 'CTP',
                path: 'clear-test-page',
                component: () => import('@/pages/develop/ctp.vue'),
                meta: { title: 'CTP', icon: 'deployed_code' },
            },
            {
                name: 'UIUXSet',
                path: 'ui-ux-set',
                component: () => import('@/pages/develop/UIUXSet.vue'),
                meta: { title: '主題樣式', icon: 'address-book' },
            },
            {
                name: 'IconList',
                path: 'icon-list',
                component: () => import('@/pages/develop/IconList.vue'),
                meta: { title: 'Icon列表', icon: 'address-book' },
            },
        ]
    },
    {
        // 未定義網址 => 404
        name: 'PageNotFound',
        path: '/:pathMatch(.*)*',
        component: () => import('@/pages/PageNotFound.vue'),
        meta: { title: '頁面不存在!!', icon: 'cross-circle' },
    },
];

export default routes;
